import React, {
    createContext,
    useContext,
    useState,
    useEffect,
  } from "react";

  import ServiceStorage from "../services/storage";

  interface UserState {
    name: string;
    avatar: string;
    token: string;
  }

  interface AuthContextData {
    user: UserState;
    loading: boolean;
    token: string;
  }

  const AuthContext = createContext<AuthContextData>({} as AuthContextData);

  const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<UserState>({
        name: '',
        avatar: '',
        token: ''

    } as UserState)

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      async function loadStoragedData(): Promise<void> {
        const getUser = await ServiceStorage.getUser()

        if (getUser) {
            setUser({ token: getUser.token, name: getUser.name, avatar: getUser.avatar })
        }

        setLoading(false);
      }

      loadStoragedData();
    }, []);

    return (
      <AuthContext.Provider value={{ user, loading, token: user.token }}>
        {children}
      </AuthContext.Provider>
    );
  };

  function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
      throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
  }

  export { AuthProvider, useAuth };
