import { createStore, combineReducers } from 'redux'

import rootReducer from './root'
import adminReducer from './admin'

const allReducer = combineReducers({
    rootReducer,
    adminReducer,
})

// let devtools: any = 'devToolsExtension'

const store = createStore(allReducer)

export default store
