import { keyApp } from '../../config'

import ServiceStorage from '../../services/storage'

const ServiceUtil = {

    getHeaders: (params = {}) => {
        const user = ServiceStorage.getUser();
        const token = user ? 'Bearer '.concat(user.token) : ''
        const config = {
            headers: {
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'Authorization' : token
            },
            params,
            timeout: 60000
        }

        return config
    },

    getHeadersFiles: (onProgress) => {
        const user = ServiceStorage.getUser()
        const token = user ? 'Bearer '.concat(user.token) : ''

        let config = {
            headers: {
                'Accept'            : 'application/json',
                'Content-Type'      : 'multipart/form-data',
                'Authorization'     : token
            },
            params: {
                'keyApp': keyApp
            },
            timeout: 500000,
            onUploadProgress: ({ total, loaded }) => {
                onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) });
            }
        }

        return config
    }
}

export default ServiceUtil
