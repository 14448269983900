import { useEffect, useState } from 'react'
import { message } from 'antd'

import CardLogs from '../../components/cards/CardLogs'
import LoadingContent from '../../components/loading/LoadingContent'
import Empty from '../../components/empty/Empty'

// Types
import { Log } from '../../types'

// Services
import ServiceMonitoring from '../../services/monitoring'

export default function Home() {
    const [list, setList] = useState<Log[]>([])
    const [lastMessage, setLastMessage] = useState<Log>()

    useEffect(() => {
        if (lastMessage) {
            const newLogs = [...list]

            newLogs.unshift(lastMessage)

            setList(newLogs)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage])

    const getList = async () => {
        try {
            const response = await ServiceMonitoring.logsList(1, 10)
            setList(response.data)
        } catch (error) {
            console.log(error)
            message.error('Erro ao buscar a lista')
        }
    }

    const done = async (log: any) => {
        try {
            await ServiceMonitoring.logsDone(log.id_log)
            getList()
        } catch (error) {
            console.log(error)
            message.error('Erro ao fechar o log')
        }
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className='box logs'>
            <div className='boxContent'>
                <LoadingContent>
                    {list.length === 0 ? (
                        <Empty description='Nenhum log por aqui' />
                    ) : (
                        <>
                            {list.map((log: Log, k: number) => (
                                <CardLogs log={log} key={k} action={done} />
                            ))}
                        </>
                    )}
                </LoadingContent>
            </div>
        </div>
    )
}
