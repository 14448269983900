import serviceUtil from '../../services/api/util'
import { api } from '../../services/api'

const baseUrl = 'person'

const Service = {
    list: function(limit = 25, page = 1, uf = null, name = "", categories = [], excludes = []) {
        const url = `${baseUrl}/juridical/list`;
        return api.post(url, { limit, page, uf, name, categories, excludes }, serviceUtil.getHeaders())
    },

    listMy: function(limit = 50, page = 1, categories = [], excludes = []) {
        const url = `${baseUrl}/juridical/list-my`;
        return api.post(url, { limit, page, categories, excludes }, serviceUtil.getHeaders())
    },

    findSlug: function(slug) {
       const url = `${baseUrl}/juridical/find-slug`;
       return api.post(url, { slug }, serviceUtil.getHeaders())
   },

    /**
     * Aprovações
     */
    clubsApprove: function(page = 1, limit = 20) {
        const url = `${baseUrl}/juridical/clubs-approve`;
        return api.post(url, { page, limit }, serviceUtil.getHeaders())
    },

    storesApprove: function(page = 1, limit = 20) {
        const url = `${baseUrl}/juridical/stores-approve`;
        return api.post(url, { page, limit }, serviceUtil.getHeaders())
    },

    approve: function(id, slug, type) {
        const url = `${baseUrl}/juridical/approve`;
        return api.post(url, { id, slug, type }, serviceUtil.getHeaders())
    },

    remove: function(id, type) {
        const url = `${baseUrl}/juridical/remove`;
        return api.post(url, {id, type}, serviceUtil.getHeaders())
    },
}

export default Service
