import React from "react";

import { AuthProvider } from "./auth";
import { SupabaseProvider } from "./supabase";
import { WsProvider } from "./ws";

const AppProvider: React.FC = ({ children }) => {
    return (
        <AuthProvider>
            <SupabaseProvider>
                <WsProvider>{children}</WsProvider>
            </SupabaseProvider>
        </AuthProvider>
    );
};

export default AppProvider;
