interface Props {
    description: string
    slim: boolean
}

const Empty = ({ description, slim }: Props) => (
    <div className={["resultEmpty", slim ? "slim" : ""].join(" ")}>
        <div className="empty">
            <span className="">{description}</span>
        </div>
    </div>
)

Empty.defaultProps = {
    slim: false
}

export default Empty
