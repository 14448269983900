import { createActions, createReducer } from 'reduxsauce'

import ServiceStorage from '../services/storage'

import { TypeUser } from '../types'

export const { Types, Creators } = createActions({
    setIsLoading : ['status'],
    setUser : ['user'],
    setStores : ['list']
})

const INITIAL_STATE = {
    isOnline: false,
    user: ServiceStorage.getUser() as TypeUser | null,
    stores: [],
    menu: []
}

/**
 * Functions
 */
const fnSetIsLoading = (state = INITIAL_STATE, action: any) => Object.assign({}, state, { isLoading: action.status })
const fnSetStores = (state = INITIAL_STATE, action: any) => Object.assign({}, state, { stores: action.list })
const fnSetUser = (state = INITIAL_STATE, action: any) => {
    ServiceStorage.setUser(action.user)
    return Object.assign({}, state, { user: action.user })
}

/**
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
    [Types.SET_IS_LOADING]: fnSetIsLoading,
    [Types.SET_STORES]: fnSetStores,
    [Types.SET_USER]: fnSetUser,
})
