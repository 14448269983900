import { useEffect, useState } from "react"
import { Button, message } from "antd"

import Header from "../../components/layout/Header"

import CardStoreApprove from "../../components/cards/CardStoreApprove"
import LoadingContent from "../../components/loading/LoadingContent"
import Empty from "../../components/empty/Empty"

// Types
import { StoreApprove } from "../../types"

// Hooks

// Services
import ServiceStore from "../../services/store"

export default function Home() {

    const [list, setList] = useState<StoreApprove[]>([])

    const getList = async () => {
        try {
            const response = await ServiceStore.storesApprove(1, 10)
            setList(response.data)
        } catch (error: any) {
            console.log(error?.message)
            message.error('Erro ao buscar a lista')
        }
    }

    const done = async (club: StoreApprove, slug: string, approve: boolean) => {
        try {
            let response: any = null

            if(approve) {
                response = await ServiceStore.approve(club.id, slug, 'L')
            } else {
                response = await ServiceStore.remove(club.id, 'L')
            }

            if(response) {
                message.success(response.message)
                getList()
            }
        } catch (error: any) {
            console.log(error?.message)
            message.error('Erro ao aprovar a loja')
        }
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className="contentAdmin">
            <Header />

            <div className="limitadorContent">
                <div className="content">
                    <div className="boxLeft">
                        <div className="box grow">
                            <Button className="btnPrimarySmall" block onClick={() => getList()}>
                                Atualizar
                            </Button>
                        </div>
                    </div>

                    <div className="boxRight">
                        <div className="boxContent">
                            <LoadingContent>
                                {list.length === 0 ? <Empty description="Nenhuma loja nova" /> :
                                <>
                                    { list.map((store: StoreApprove, k: number) =>
                                        <CardStoreApprove store={store} key={k} action={done} />) }
                                </>
                                }
                            </LoadingContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
