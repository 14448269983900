import { MapContainer, Marker, Tooltip } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { LatLng, LatLngExpression } from 'leaflet'

import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility'

import { googleMapsApi } from '../../config'

interface LabelMarkerProps {
    position: LatLng
    label: string
}

interface PropsMap {
    markers: any[]
}

const Map = ({ markers }: PropsMap) => {
    const center: LatLngExpression = [-15.807, -48.03224]

    const zoom = 8

    function LabelMarker({ position, label }: LabelMarkerProps) {
        return (
            <Marker position={position}>
                <Tooltip direction='top' offset={[-15, 0]} permanent>
                    {label}
                </Tooltip>
            </Marker>
        )
    }

    return (
        <MapContainer
            // dragging={false}
            center={center}
            zoom={zoom}
            scrollWheelZoom={false}
        >
            <ReactLeafletGoogleLayer apiKey={googleMapsApi} type='roadmap' />

            {markers &&
                markers.map((marker: any, k: number) => {
                    if (!marker?.location) {
                        return null
                    }

                    const latLng: any[] = marker.location.split(',')

                    if (!latLng || latLng.length === 0) {
                        return null
                    }

                    const position = {
                        lat: parseFloat(latLng[0]),
                        lng: parseFloat(latLng[1]),
                    } as LatLng

                    // console.log(marker?.name, position)

                    if (position.lat && position.lng) {
                        return <LabelMarker key={k} position={position} label={`${marker?.name}`} />
                    }

                    return null
                })}
        </MapContainer>
    )
}

export default Map
