import { Button } from "antd"
import moment from "moment"

import { StoreApprove } from "../../types"

interface Props {
    store: StoreApprove
    action: (store: StoreApprove, slug: string, approve: boolean) => void
}

const CardStoreApprove = ({ store, action }: Props) => {

    return (
        <div className="card log">
            <div className="type">
                {store.name}
            </div>
            <div className="date">
                {store.created_at && moment.unix(store.created_at).format('LLL')}
            </div>
            <div>
                <b>Local:</b> {store.city} - {store.estate}
            </div>
            <div>
                <b>Proprietário:</b> {store.owner}
            </div>
            <div>
                <b>Slug:</b> {store.slug}
            </div>
            <div className="bug">
                {store.services}
            </div>
            <div className="footer">
                <Button className="btnSecondary mr"onClick={() => action(store, store.slug, false)}>
                    Reprovar
                </Button>
                <Button
                    className={["btnPrimary"].join(" ")}
                    onClick={() => action(store, store.slug, true)}>
                        Aprovar
                </Button>
            </div>
        </div>
    )
}

export default CardStoreApprove
