const ServiceStorage = {

    setItem: function(name, item) {
        sessionStorage.setItem(`ac@${name}`, JSON.stringify(item))
    },

    getItem: function(name) {
        let item = sessionStorage.getItem(`ac@${name}`)
        if(!item) return false
        return JSON.parse(item)
    },

    removeItem: function(name) {
        sessionStorage.removeItem(`ac@${name}`)
    },

    removeUser: function() {
        sessionStorage.removeItem('user')
    },

    setUser: function(user) {
        sessionStorage.removeItem('user')
        sessionStorage.setItem('user', JSON.stringify(user))
    },

    getUser: function() {
        const user = sessionStorage.getItem('user')

        if(!user || user === 'undefined')
            return null

        return JSON.parse(user)
    }
}

export default ServiceStorage;
