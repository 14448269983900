import moment from "moment"

import { UserOnline } from "../../types"
import { SimpleImage } from "../images/SimpleImage"

interface Props {
    user: UserOnline
}

const CardUserOnline = ({ user }: Props) => {

    return (
        <div className="card user-online">

            <SimpleImage
                className="img-user-online"
                url={`${user.avatar}`}
            />

            <div className="name">
                {user.name}
            </div>
            <div className="app">
                {user.app}
            </div>
            <div className="date">
                {moment.unix(parseInt(user.created_at)).fromNow()}
            </div>
        </div>
    )
}

export { CardUserOnline }
