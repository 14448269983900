import { useEffect } from 'react'

import Header from '../../../components/layout/Header'

import { UserOnline } from '../../../types'

// Hooks
import { useAuth } from '../../../store/useAuth'
import { CardUserOnline } from '../../../components/cards/CardUserOnline'

export default function Home() {
    const { usersOnline, setUsersOnline } = useAuth()

    return (
        <div className='contentAdmin'>
            <Header />

            <div className='limitadorContent'>
                <div className='content'>
                    <div className='boxCenter'>
                        <div className='boxContent'>
                            <div className='grid-users'>
                                {usersOnline.map((user) => (
                                    <CardUserOnline key={user.id} user={user} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
