import { useEffect, useState } from 'react'
import { Button, message } from 'antd'

import Header from '../../components/layout/Header'

import CardLogs from '../../components/cards/CardLogs'
import LoadingContent from '../../components/loading/LoadingContent'
import Empty from '../../components/empty/Empty'
import { useSupabase } from '../../hooks/supabase'

// Types
import { Log } from '../../types'

// Hooks
import { useWS } from '../../hooks/ws'

// Services
import ServiceMonitoring from '../../services/monitoring'

export default function Home() {
    const { supabase } = useSupabase()

    const [list, setList] = useState<Log[]>([])

    const [lastMessage, setLastMessage] = useState<Log>()

    useEffect(() => {
        if (lastMessage) {
            const newLogs = [...list]

            newLogs.unshift(lastMessage)

            setList(newLogs)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage])

    const getList = async () => {
        let { data, error } = await supabase.from('tb_users_online').select('*')

        if (error) {
            console.log(error)
        } else {
            console.log(data)
            setList(data)
        }
    }

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        supabase.from('tb_users_online').on('*', getList).subscribe()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const done = async (log: any) => {
        try {
            await ServiceMonitoring.logsDone(log.id_log)
            getList()
        } catch (error: any) {
            console.log(error?.message)
            message.error('Erro ao fechar o log')
        }
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className='contentAdmin'>
            <Header />

            <div className='limitadorContent'>
                <div className='content'>
                    <div className='boxLeft'>
                        <div className='box grow'>
                            <Button className='btnPrimarySmall' block onClick={() => getList()}>
                                Atualizar
                            </Button>
                        </div>
                    </div>

                    <div className='boxRight'>
                        <div className='boxContent'>
                            <LoadingContent>
                                {list.length === 0 ? (
                                    <Empty description='Nenhum log por aqui' />
                                ) : (
                                    <>
                                        {list.map((log: Log, k: number) => (
                                            <CardLogs log={log} key={k} action={done} />
                                        ))}
                                    </>
                                )}
                            </LoadingContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
