import axios from 'axios'

import { baseUrl } from '../../config'
import { baseUrlUpLoad } from '../../config'

const success = (response) => {
    if (response.data.error) {
        return Promise.reject(response.data)
    }
    return response.data
}

const error = (error) => {
    if (error.response.status === 500) {
        return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response.data)
}

const api = axios.create({
    baseURL: baseUrl
})

const apiUpload = axios.create({
    baseURL: baseUrlUpLoad
})

api.interceptors.response.use(success, error)
apiUpload.interceptors.response.use(success, error)

export { api, apiUpload }
