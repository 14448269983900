import React, { memo } from "react"
import { Avatar, Dropdown, Image, Menu } from "antd"

// Redux
import { connect } from "react-redux"

import Logo from "../../assets/images/logo.svg"

import { TypeUser } from '../../types'
import { baseUrlImage } from "../../config"
import { MenuOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

interface Props {
    user?: TypeUser | undefined
    monitor?: boolean
}

const Header = ({ user, monitor  }: Props) => {

    const menuGeral = (
        <Menu>
            <Menu.Item>
                <Link to="/monitor">
                    Monitor
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/users">
                    Usuários Online
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/users/map">
                    Usuários Online Mapa
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/clubs">
                    Clubes
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/stores">
                    Lojas
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="header">
            <div className={["limitadorHeader", monitor ? "monitor":""].join(" ")}>
                <div className="logo">
                    <Image src={Logo} />
                </div>
                <div className="icons">

                    <Dropdown overlay={menuGeral} placement="bottomRight" trigger={['click']}>
                        <MenuOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                    </Dropdown>

                    {user &&
                        <Avatar src={`${baseUrlImage}/450/${user.avatar}`} />
                    }
                </div>
            </div>
        </div>
    )
}

Header.defaultProps = {
    monitor: false
}

const mapStateToProps = (state: any) => ({
    user: state.rootReducer.user
})

export default connect(mapStateToProps, null)(memo(Header))
