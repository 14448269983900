import serviceUtil from '../../services/api/util'
import { api } from '../../services/api'

const Service = {

    /**
     * Mapa
     */
     mapClubs: function() {
        const url = `person/monitoring/map-clubs`
        return api.get(url, serviceUtil.getHeaders())
    },

    mapStores: function() {
        const url = `person/monitoring/map-stores`
        return api.get(url, serviceUtil.getHeaders())
    },

    mapMembers: function() {
        const url = `person/monitoring/map-members`
        return api.get(url, serviceUtil.getHeaders())
    },

    mapAD: function() {
        const url = `shop/monitoring/map-ad`
        return api.get(url, serviceUtil.getHeaders())
    },

    /**
     * Logs
     */

     logsList: function(page = 1, limit = 20) {
        const url = `admin/logs/list`
        return api.post(url, { page, limit }, serviceUtil.getHeaders())
    },

     logsDone: function(id) {
        const url = `admin/logs/done/${id}`
        return api.get(url, serviceUtil.getHeaders())
    },
}

export default Service
