import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import socketIOClient, { Socket } from 'socket.io-client'

import { baseUrlWS, idApp } from '../config'

import { useAuth } from './auth'

interface WsContextData {
    conn: Socket | undefined
    isConnected: boolean
    joinRoom(room: string): Promise<void>
    leaveRoom(room: string): Promise<void>
}

const WsContext = createContext<WsContextData>({} as WsContextData)

const WsProvider: React.FC = ({ children }) => {
    const { user, token } = useAuth()

    const [isConnected, setIsConnected] = useState(false)

    // const conn = useMemo<Socket>(() => socketIOClient(baseUrlWS, config), [config])

    const socket = useRef<Socket>()

    useEffect(() => {
        if (token && !isConnected) {
            socket.current = socketIOClient(baseUrlWS, {
                extraHeaders: {
                    Authorization: `Bearer ${token}`,
                },
                query: {
                    appID: idApp,
                    name: user?.name,
                    avatar: user?.avatar,
                    location: ``,
                },
            })

            socket.current?.on('connect', () => {
                setIsConnected(true)
                console.log('Connected')
            })

            socket.current?.on('disconnect', () => {
                setIsConnected(false)
                console.log('Disconnected')
            })

            socket.current?.on('keepAlive', (msg) => {
                socket.current?.emit('isAlive', { message: 'isAlive' })
            })
        }
    }, [token])

    const joinRoom = useCallback(async (room: string) => {
        socket.current?.emit('joinRoom', { room })
    }, [])

    const leaveRoom = useCallback(async (room: string) => {
        socket.current?.emit('leaveRoom', { room })
    }, [])

    return (
        <WsContext.Provider value={{ isConnected, conn: socket?.current, joinRoom, leaveRoom }}>
            {children}
        </WsContext.Provider>
    )
}

function useWS(): WsContextData {
    const context = useContext(WsContext)

    if (!context) {
        throw new Error('useWS must be used within an AuthProvider')
    }

    return context
}

export { WsProvider, useWS }
