import { Button } from "antd"
import moment from "moment"

import { Log } from "../../types"

interface Props {
    log: Log
    action: (log: any) => void
}

const CardLogs = ({ log, action }: Props) => {

    return (
        <div className="card log">
            <div className="type">
                {log.type}
            </div>
            <div className="date">
                {log.created_at && moment.unix(log.created_at).format('LLL')}
            </div>
            <div>
                <b>Service:</b> {log.service}
            </div>
            <div>
                <b>Controller:</b> {log.controller}
            </div>
            <div>
                <b>method:</b> {log.method}
            </div>
            <div className="bug">
                {log.message}
            </div>
            <div className="footer">
                <Button className="btnPrimary mr disabled" disabled>Trello</Button>
                <Button className="btnPrimary" onClick={() => action(log)}>Done</Button>
            </div>
        </div>
    )
}

export default CardLogs
