import { Button, Input } from "antd"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"

import { StoreApprove } from "../../types"

import ServiceStore from "../../services/store"

interface Props {
    store: StoreApprove
    action: (store: StoreApprove, slug: string, approve: boolean) => void
}

const CardClubApprove = ({ store, action }: Props) => {

    const [slug, setSlug] = useState<string>(store.slug)
    const [list, setList] = useState([])

    const findSlug = useCallback(async () => {
        try {
            if(slug) {
                console.log('find', slug)
                const response = await ServiceStore.findSlug(slug)
                setList(response.data)
            }
        } catch (error) {
            console.error(error)
        }
    }, [slug])

    useEffect(() => {
        findSlug()

    }, [findSlug])

    const changeSlug = (e:any) => {
        setSlug(e.target.value)
    }

    return (
        <div className="card log">
            <div className="type">
                {store.name}
            </div>
            <div className="date">
                {store.created_at && moment.unix(store.created_at).format('LLL')}
            </div>
            <div>
                <b>Local:</b> {store.city} - {store.estate}
            </div>
            <div>
                <b>Presidente:</b> {store.owner}
            </div>
            <div className="bug">
                {store.description}
            </div>
            <div className="bug">
                {store.slug}
            </div>
            <div className="bug" style={{ display: 'flex' }}>
                <Input placeholder="slug" defaultValue={store.slug} onChange={changeSlug} />
            </div>

            { list.length > 0 &&
                <div className="bug">
                    <div className="title">Clubes Relacionados</div>
                    {list.map((club: any, k) => (
                        <div key={k}>
                            {club.name}
                        </div>
                    ))}
                </div>
            }

            <div className="footer">
                <Button className="btnSecondary mr"onClick={() => action(store, slug, false)}>
                    Reprovar
                </Button>

                <Button
                    className={["btnPrimary", list.length > 0 ? "disabled" : ""].join(" ")}
                    onClick={() => action(store, slug, true)}>
                        Aprovar
                </Button>
            </div>
        </div>
    )
}

export default CardClubApprove
