import create from 'zustand'

import { TypeUser, UserOnline } from '../types'

type State = {
    user: TypeUser | undefined
    setUser(user: TypeUser | undefined): void

    usersOnline: UserOnline[]
    setUsersOnline(users: UserOnline[]): void
}

export const useAuth = create<State>((set, get) => (
    {
        user: undefined,
        setUser(user: TypeUser) {
            set({ user })
        },

        usersOnline: [],
        setUsersOnline(users: UserOnline[]) {
            set({ usersOnline: users })
        },

} as State))
