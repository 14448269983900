import { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input, Button, message, Avatar } from "antd"
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom"
// Redux
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Creators as rootActions } from "../../redux/root"

// Types
import { TypeUser } from "../../types"

// Components
import LayoutAuth from '../../components/layout/LayoutAuth';

// Config
import { baseUrlImage } from '../../config';

// API
import ServiceAuth from '../../services/auth'

interface Props {
    stores: any[];
    user: TypeUser | null;
    setUser: (user: any) => void;
    setStores: (list: any[]) => void;
}

const Login = ({ user, setUser, stores, setStores }: Props) => {

    const [form] = Form.useForm()

    const router = useHistory()

    const [loading, setLoading] = useState(false)

    const login = async () => {
        try {
            setLoading(true)
            await form.validateFields()
            const email = form.getFieldValue('email')
            const password = form.getFieldValue('password')

            const response: any = await ServiceAuth.login(email, password)

            const user = response.session

            setUser(user)

            setTimeout(() => {
                router.push('/monitor')
            }, 3000)

        } catch (error: any) {
            if(error.error && error.error.message) {
                message.error(error.error.message)
            } else {
                message.error('Login inválido')
            }
        }
    }

    return (
        <LayoutAuth>
            <>
                <div style={{ display: 'flex', flexDirection: 'column', height: '200px', justifyContent: 'center'  }}>
                    {loading ?
                        <div className="description" style={{ textAlign: 'center' }}>
                            {user?.token &&
                                <>
                                    <div style={{ textAlign: 'center', padding: '10px' }}>
                                        <Avatar size={80} shape="square" src={`${baseUrlImage}/450/${user?.avatar}`} icon={<UserOutlined />} />
                                    </div>
                                    <div className="title" style={{ textAlign: 'center' }}>
                                        {user.name}
                                    </div>
                                </>
                            }
                            Aguarde, verificando permissões...
                        </div>
                        :
                        <>
                            <Form name="formlogin" form={form} className="formLogin" layout="vertical" >
                                <Form.Item
                                    name="email"
                                    label="E-mail"
                                    rules={[{ required: true, message: "Insira um login valido" }]}
                                >
                                    <Input placeholder="email@email.com" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Senha"
                                    rules={[{ required: true, message: "Insira a senha correta" }]}
                                >
                                    <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                </Form.Item>
                            </Form>

                            <Button className="btnPrimary" block onClick={login}>
                                Login
                            </Button>
                        </>
                    }

                </div>

            </>
        </LayoutAuth>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.rootReducer.user,
    stores: state.rootReducer.stores,
})

const dispachProps = (dispatch: any) => bindActionCreators(rootActions, dispatch)

export default connect(mapStateToProps, dispachProps)(Login)
