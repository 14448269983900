import Header from "../../components/layout/Header"

import Maps from './Maps'
import Logs from './Logs'

export default function Home() {

    return (
        <div className="contentAdmin">
            <Header monitor />
            <div className="limitadorContent monitor">
                <div className="monitor">
                    <div className="container">
                        <Logs />
                        <Maps />
                    </div>
                </div>
            </div>
        </div>
    )
}
