import ReactDOM from 'react-dom'

import 'react-aspect-ratio/aspect-ratio.css'
import './assets/styles/globals.scss'
import 'moment/locale/pt'

// Redux
import { Provider } from "react-redux"
import store from "./redux/store"

import Routes from './routes'

import AppProvider from './hooks/index'

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <Routes />
    </AppProvider>
  </Provider>,
  document.getElementById('root')
)
