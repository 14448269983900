import React from "react";
import { baseUrlImage } from "../../config";

interface Props {
    url: string;
    className?: string | undefined;
}

const SimpleImage = ({ url, className }: Props) => {

    return (
        <div
            style={{backgroundImage:`url(${baseUrlImage}/450/${url})`}}
            className={`${className}`}
        />
    );
}

export { SimpleImage }
