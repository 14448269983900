import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
    setSearch : ['data']
})

const INITIAL_STATE = {
    search: [],
}

/**
 * Functions
 */
const fnSetSearch = (state = INITIAL_STATE, action: any) => Object.assign({}, state, { search: action.data })

/**
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
    [Types.SET_SEARCH]: fnSetSearch,
})
