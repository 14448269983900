import React, { createContext, useContext, useMemo } from "react"

import { createClient } from '@supabase/supabase-js'
import { SUPABASE_KEY, SUPABASE_URL } from "../config"


interface SupabaseContext {
    supabase: any
}

const Supabase = createContext<SupabaseContext>({} as SupabaseContext)

const SupabaseProvider: React.FC = ({ children }) => {

    const supabase = useMemo(() => createClient(`${SUPABASE_URL}`,`${SUPABASE_KEY}`), [])

    return (
        <Supabase.Provider value={{ supabase }}>
            {children}
        </Supabase.Provider>
    )
}

function useSupabase(): SupabaseContext {
    const context = useContext(Supabase)

    if (!context) {
        throw new Error("useSupabase must be used within an AuthProvider")
    }

    return context
}

export { SupabaseProvider, useSupabase }
