import serviceUtil from '../../services/api/util'
import { api } from '../../services/api'

const ServiceAuth = {
    login: function(email, password) {
        const url = `person/account/login-adm`;
        return api.post(url, { email, password }, serviceUtil.getHeaders())
    },

    renew: function() {
        const url = `person/account/renew`;
        return api.get(url, serviceUtil.getHeaders())
    },

    create: function(data) {
        const url = `person/account/create`;
        return api.post(url, data, serviceUtil.getHeaders())
    },

    restore: function(email) {
        const url = `person/account/restore`;
        return api.post(url, { email }, serviceUtil.getHeaders())
    },

    saveRestore: function(password, keygen) {
        const url = `person/account/restore`;
        return api.put(url, { password, keygen }, serviceUtil.getHeaders())
    },

    savePassword: function(data) {
        const url = `person/account/save-password`;
        return api.put(url, data, serviceUtil.getHeaders())
    },
}

export default ServiceAuth
