import React, { ReactNode } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
    description: string
    loading: boolean
    children: ReactNode
}

const LoadingContent = ({ description, loading, children }: Props) => (
    <>
        {loading ?
            <div className="resultEmpty">
                <div className="empty">
                    <LoadingOutlined /> {description}
                </div>
            </div>
            :
            <>
                {children}
            </>
        }
    </>
)

LoadingContent.defaultProps = {
    description: 'Carregando...',
    loading: false,
}

export default LoadingContent
