import { BrowserRouter, Switch, Route } from "react-router-dom";

import Page404 from "../pages/404";
import Login from "../pages/login";
import Logs from "../pages/logs";
import Clubs from "../pages/clubs";
import Stores from "../pages/stores";
import Monitor from "../pages/monitor";

import UserOnline from "../pages/maps/UsersOnline";
import UserOnlineMap from "../pages/maps/UsersOnlineMap";

const Routes = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact={true} component={Login} />
                <Route path="/monitor" component={Monitor} />
                <Route path="/logs" component={Logs} />
                <Route path="/clubs" component={Clubs} />
                <Route path="/stores" component={Stores} />
                <Route path="/users/map" component={UserOnlineMap} />
                <Route path="/users" component={UserOnline} />
                <Route path="*" component={Page404} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
