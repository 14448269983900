// export const baseUrlWs = 'ws://177.71.228.128:5000'
export const keyApp = 'oCIQKlWEIDmUsMdaTiW4NojvB5frfFvr'
// export const googleMapsApi = 'AIzaSyAvf0vgt75EQ9_hnayv-fVKzEvfy14TS5E'
export const googleMapsApi = 'AIzaSyAMP0wr6BnOQTA0DYjQ6cbaHo9BdCdYyU0'
export const idApp = 5

// ECS
export const baseUrl = 'https://app.autoclubes.com.br/'
export const baseUrlUpLoad = 'https://app.autoclubes.com.br/'
export const baseUrlImage = 'https://app.autoclubes.com.br/static'
export const baseUrlWS = 'https://app.autoclubes.com.br'

// // Production
// export const baseUrl = 'http://api.autoclubes.com.br/'
// export const baseUrlUpLoad = 'http://api.autoclubes.com.br/'
// export const baseUrlImage = 'http://api.autoclubes.com.br/static'

// Development
// export const baseUrl = 'http://localhost/'
// export const baseUrlUpLoad = 'http://localhost/'

// Configurações Gerais
export const limitPages = 32
export const loadingTimeout = 700

export const SUPABASE_URL = 'https://eccovbgkezousxztlmwf.supabase.co'
export const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYyNjI2NzAyNCwiZXhwIjoxOTQxODQzMDI0fQ.3_w9zBocSInysFM9WJP_opAyNmMEf1vac9lDj5GTcqo'
