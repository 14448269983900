import { useCallback, useEffect, useState } from 'react'

import Maps from '../../../components/maps'

// Hooks
import { useWS } from '../../../hooks/ws'

export default function Home() {
    const { isConnected, conn, joinRoom, leaveRoom } = useWS()

    const [list, setList] = useState<any[]>([])

    const receiveMessage = useCallback((message: any) => {
        if (message?.type !== 'welcome') {
            const { user } = message

            if (user && user.location) {
                const find = list.find((u) => Number(u.id) === Number(user.id))

                if (find && user.off) {
                    console.log('A', user)

                    const newUsers = list.filter((u) => Number(u.id) !== Number(user.id))

                    setList(newUsers)
                } else if (find) {
                    console.log('B', user)

                    find.date = user.date
                } else {
                    console.log('C', user)
                    setList((old) => [...old, user])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (conn && isConnected) {
            joinRoom('users')
            conn.on('users', receiveMessage)

            joinRoom('keepAlive')
            conn.on('keepAlive', () => conn.emit('isAlive', { message: 'isAlive' }))
        }

        return function cleanup() {
            conn?.off('users', receiveMessage)
            leaveRoom('users')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, conn])

    return (
        <div className='contentAdmin'>
            <div className='limitadorContent'>
                <div className='content'>
                    <div className='boxCenter'>
                        <Maps markers={list} />
                    </div>
                </div>
            </div>
        </div>
    )
}
