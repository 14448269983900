import React from "react";

import Logo from "../../assets/images/logo.svg"

interface Props {
    children: React.ReactElement
}

const LayoutAuth = ({ children }: Props) => {

    return (
        <div className="contentAuth">
            <div className="boxCenter">
                <div className="conteinerForm">
                    <div className="logo">
                        <img src={Logo} alt="logo" />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LayoutAuth;
